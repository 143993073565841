/*
@font-face {
    font-family: "Selawik";
    src: url("../fonts/selawk/selawk.eot");
    src: url("../fonts/selawk/selawk.eot#iefix") format("embedded-opentype"),
         url("../fonts/selawk/selawk.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Selawik Bold";
    src: url("../fonts/selawk/selawkb.eot");
    src: url("../fonts/selawk/selawkb.eot#iefix") format("embedded-opentype"),
         url("../fonts/selawk/selawkb.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Selawik Light";
    src: url("../fonts/selawk/selawkl.eot");
    src: url("../fonts/selawk/selawkl.eot#iefix") format("embedded-opentype"),
         url("../fonts/selawk/selawkl.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Selawik Semibold";
    src: url("../fonts/selawk/selawksb.eot");
    src: url("../fonts/selawk/selawksb.eot#iefix") format("embedded-opentype"),
         url("../fonts/selawk/selawksb.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Selawik Semilight";
    src: url("../fonts/selawk/selawksl.eot");
    src: url("../fonts/selawk/selawksl.eot#iefix") format("embedded-opentype"),
         url("../fonts/selawk/selawksl.ttf") format("truetype");
  }
  
   
  
  @font-face {
    font-family: "migracao";
    src:url("../fonts/icons/migracao.eot");
    src:url("../fonts/icons/migracao.eot?#iefix") format("embedded-opentype"),
      url("../fonts/icons/migracao.woff") format("woff"),
      url("../fonts/icons/migracao.ttf") format("truetype"),
      url("../fonts/icons/migracao.svg#migracao") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  */
[data-icon]:before {
  font-family: "migracao" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "migracao" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-excluir:before {
  content: "\61"; }

.icon-agendado:before {
  content: "\62"; }

.icon-sincronizar:before {
  content: "\63"; }

.icon-concluido:before {
  content: "\64"; }

.icon-atencao:before {
  content: "\65"; }

.icon-pendente-2:before {
  content: "\66"; }

.icon-pendente:before {
  content: "\67"; }

.icon-doc:before {
  content: "\68"; }

.icon-pesquisar:before {
  content: "\69"; }

.icon-concluido-lot:before {
  content: "\6a"; }

.icon-origem-migrada:before {
  content: "\6b"; }

.icon-origem-vinculada:before {
  content: "\6c"; }

.icon-erro404:before {
  content: "\6d"; }

.icon-up:before {
  content: "\6e"; }

.icon-down:before {
  content: "\6f"; }

.icon-close:before {
  content: "\70"; }

.icon-origem-vinculada:before {
  content: "\6c"; }

.icon-voltar:before {
  content: "\71"; }

div {
  font-family: "Segoe UI", "Selawik", Tahoma, Verdana, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px; }
