//Colors
$gray-100: #F9F9F9 !default;
$gray-200: #F2F2F2 !default;
$gray-300: #E7E7E7 !default;
$gray-400: #A6A6A6 !default;
$gray-500: #808080 !default;
$gray-600: #4D4D4D !default;
$gray-700: #333333 !default;
$white: #FFFFFF !default;
$black: #000000 !default;
$danger: red;
$default: $gray-300 !default;
$primary: #0078D7 !default;
$secondary: #FFC16F !default;

// Brand
$themeDarker: #03508D !default;
$themeDark: #0063B1 !default;
$themePrimary: #0078D7 !default;
$themeLight: #CBE3F6 !default;

$orangeDark: #FFAF4A !default;
$orange: #FFC070 !default;

// Functional
$warningDark: #856000 !default;
$warning: #FFB900 !default;
$warningBackground: #FFF1CC !default;

$dangerDark: #95020B !default;
$dangerHover: #C40E1E !default;
$danger: #E81123 !default;
$dangerBackground: #FACFD3 !default;

$successDark: #007133 !default;
$success: #00CC6A !default;
$successBackground: #CCF5E1 !default;

$infoDark: #0063B1 !default;
$infoBackground: #DCF0FF !default;
// End Brand

$theme-colors: () !default;
$theme-colors: map-merge(( "default": $default,
), $theme-colors);


// Fonts
//@import "selawk/selawk";

$font-family-fallbacks: Tahoma, Verdana, Arial, sans-serif !default;
$font-family-sans-serif: "Segoe UI", "Selawik", $font-family-fallbacks !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;


@mixin font-light(){
  font-family: "Segoe UI Light", "Segoe UI", "Selawik Light", $font-family-fallbacks;
  font-weight: 100;
}
@mixin font-semilight(){
  font-family: "Segoe UI Semilight", "Segoe UI", "Selawik Semilight", $font-family-fallbacks;
  font-weight: 300;
}
@mixin font-regular(){
  font-family: "Segoe UI", "Selawik", $font-family-fallbacks;
  font-weight: 400;
  font-size: 14px;
}
@mixin font-semibold(){
  font-family: "Segoe UI Semibold", "Segoe UI", "Selawik Semibold", $font-family-fallbacks;
  font-weight: 600;
}
@mixin font-bold(){
  font-family:  "Segoe UI Bold", "Segoe UI", "Selawik Bold", $font-family-fallbacks;
  font-weight: 700;
}


$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: 0.75rem;
$font-weight-semibold: 600;


// Buttons
$btn-border-width: 0;
$btn-border-radius: 0;
$btn-padding-y: 5.5px;
$btn-padding-x: 20px;
//Buttons Small
$btn-border-radius-sm: 0;
$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 20px;
//Buttons Large
$btn-border-radius-lg: 0;
$btn-padding-y-lg: 7px;
$btn-padding-x-lg: 20px;
//Forms
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;
$custom-select-border-radius: 0;
$input-border-color: $gray-400;
$input-placeholder-color: $gray-400;
$input-color: $gray-700;
$input-padding-x: 0.5rem;
$input-padding-x-lg: 0.5rem;
$input-padding-y: 4.5px;
$input-padding-y-lg: 5px;
$custom-select-padding-x: 0.5rem;
$custom-select-indicator: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiA3Ij4gICAgPHBhdGggaWQ9IlBhdGhfNzYiIGZpbGw9IiNhNmE2YTYiIGRhdGEtbmFtZT0iUGF0aCA3NiIgY2xhc3M9ImNscy0xIiBkPSJNMS4wNjMsNywwLDUuOTU3LDYuMDc2LDAsMTIsNS45NTcsMTAuNzg1LDcsNi4wNzYsMi4wODVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMiA3KSByb3RhdGUoMTgwKSIvPjwvc3ZnPg==);
$custom-select-bg-size: 12px 8px;
$input-height: 32px;
$custom-select-padding-y: 4.5px;
$custom-select-height-sm: 28px;
$custom-select-padding-y-sm: 4.5px;
$custom-select-font-size-sm: 12px;
$custom-select-height-lg: 36px;
$custom-select-padding-y-lg: 5px;
$custom-select-font-size-lg: 16px;
$custom-control-indicator-focus-box-shadow: none;
$input-focus-box-shadow: none;
$input-box-shadow: none;
$custom-select-focus-box-shadow: none;
$custom-select-disabled-bg: $gray-300;
$custom-select-disabled-color: $gray-400;
$input-disabled-bg: $gray-300;
$input-disabled-color: $gray-400;
$custom-checkbox-indicator-border-radius: 0;
$custom-checkbox-indicator-icon-checked: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS4xIDguNSI+ICAgIDxwYXRoIGZpbGw9IiNmZmZmZmYiIGlkPSJQYXRoXzM0MyIgZGF0YS1uYW1lPSJQYXRoIDM0MyIgY2xhc3M9ImNscy0xIiBkPSJNMTAuMywwLDMuNSw2LjguOSw0LjIsMCw1LjFsMywzLC41LjQuNC0uNEwxMS4xLjlaIi8+PC9zdmc+);
$custom-control-indicator-bg-size: 60%;
$custom-control-indicator-bg: #FFFFFF;
$custom-control-indicator-disabled-bg: $gray-300 !important;
//Alerts
$alert-border-radius: 0;
$alert-border-width: 0;
//Dropdown
$dropdown-border-color: $gray-300;
$dropdown-border-radius: 0;
$dropdown-padding-y: 5px;
//Badge
$badge-padding-y: 4.5px;
$badge-padding-x: 7px;
$badge-pill-padding-x: 10px;
$badge-border-radius:50%;
$badge-font-size: 11px;


//Pagination
$pagination-border-width: 0;
$pagination-padding-y: 0;
$pagination-padding-x:0;
$pagination-padding-y-sm:0;
$pagination-padding-x-sm:0;
$pagination-bg: rgba(0, 0, 0, 0);
$pagination-color: $gray-500;
$pagination-padding-y-lg: 0;
$pagination-padding-x-lg: 0;
$border-radius-lg: 0;
//Modal
$modal-header-padding: 8px;
$modal-header-border-width: 0;
$modal-content-border-width: 0;
$border-radius-lg: 0;
$modal-backdrop-opacity: .4;
$modal-inner-padding: 15px;

$custom-file-height: 35px;