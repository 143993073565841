.bold{font-weight:bold;}
.time{position:absolute; left:-115px; font-size: 12px;}
.timeline-wrapper {
    padding-left:80px;
    /* min-width: 400px; */
    /* font-family: 'Helvetica'; */
    font-size: 14px;
    /*border: 1px solid #CCC;*/
}
.StepProgress {
    position: relative;
    padding-left: 38px;
    list-style: none;
}
.StepProgress::before { 
    display: inline-block;
    content: '';
    position: absolute;
    top: 5px;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 2px solid #CCC;
}
.StepProgress-item {
    position: relative;
    counter-increment: list;
}
.StepProgress-item:not(:last-child) {
    padding-bottom: 15px;
}
.StepProgress-item::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -30px;
    height: 100%;
    width: 10px;
}
.StepProgress-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 5px;
    left: -27px;
    width: 10px;
    height: 10px;
    border: 2px solid #CCC;
    border-radius: 50%;
    background-color: #FFF;
}
.StepProgress-item.is-done::before {
    border-left: 2px solid green;
}
.StepProgress-item.is-done::after {
/*content: "?";*/
    font-size: 10px;
    color: #FFF;
    text-align: center;
    border: 2px solid green;
    background-color: green;
}
/*.StepProgress-item.current::before { 
border-left: 2px solid green;
}
.StepProgress-item.current::after {
content: counter(list);
padding-top: 1px;
width: 19px;
height: 18px;
top: -4px;
left: -40px;
font-size: 14px;
text-align: center;
color: green;
border: 2px solid green;
background-color: white;
}*/
.StepProgress strong {
    display: block;
}